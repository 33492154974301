import {
  FC,
  MouseEvent,
  useState,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { DataButton, DataLink } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { GAReplacementValue } from 'constants/enums';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';
import { Store } from 'app-redux/types/storeTypes';
import { setPageUrlCookie } from 'src/shared/lib/cookie';
import {
  ASK_ACCOUNT_INFO,
  CTA_TYPE,
  NC_FUNNEL_SIGN_UP_URL,
  PSYCHIC_TALK_COOKIE_BIO,
} from 'constants/constants';
import { accountInfoObject } from 'src/shared/lib/cookie/accountInfo';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import type { Block } from 'src/__generated__/graphqlTypes';
import { useCallbackReserveFunction } from 'entities/PsychicCtaButton';
import { updateCustomerDetails } from 'src/shared/lib/auth';

import styles from './styles.module.scss';
import TalkModal from './TalkModal';
import ErrorModal from './ErrorModal';

import { isSufficientFunds } from '../../lib/message';
import { ButtonIcon } from '../Icon';
import { setMultipleCookies } from '../../lib/ctaCookie';
import { TalkButtonInterface } from '../../config/declarations';
import { getUrl } from '../../lib';

const TalkButton: FC<TalkButtonInterface> = ({
  psychicFrame,
  button: link,
  psychic,
  className,
  buttonName,
  shape = 'Rectangle',
  href = '',
  onClick,
  ...rest
}) => {
  const trackExponea = useExponeaProductSelectedTracking(psychic);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { extId, psychicName } = psychic;
  const url = getUrl(href, link?.src!, { extid: extId.toString() });
  const router = useCustomRouter();
  const user = useSelector((store: Store) => store.server.auth.user);
  const isMobileView = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [isOpened, setOpenedState] = useState<boolean>(false);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const [primaryNumber, setPrimaryNumber] = useState<string>('');
  const [isOopenErrorModal, setIsOopenErrorModal] = useState<boolean>(false);
  const { talkReserve } = useCallbackReserveFunction();

  if (!link) {
    return null;
  }

  const localOnClick = async (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    trackExponea(buttonName);

    if (isAuthenticated && isMobileView && psychic?.lineStatus === 'available') {
      e.preventDefault();
      const details = await updateCustomerDetails();
      setPrimaryNumber(details?.customerPhoneNumber);

      if (psychic?.customerPlaceInQueue) {
        setIsOopenErrorModal(true);
      } else if (isSufficientFunds(psychic.customerPrice, details)) {
        setOpenedState(true);
      } else {
        router.push(url);
      }
    }

    if (user && !user?.isLeadCustomer && !isMobileView) {
      e.preventDefault();
      const customerDetails = await updateCustomerDetails();

      if (customerDetails?.enableAutoRecharge) {
        await talkReserve(user?.custId, extId);
      }

      router.push(url);
    }

    setMultipleCookies({
      [CTA_TYPE]: JSON.stringify(buttonName.toLowerCase()),
      [PSYCHIC_TALK_COOKIE_BIO]: { ExtId: extId, Name: psychicName },
      ...(user && { [ASK_ACCOUNT_INFO]: { ...accountInfoObject, extId, chat: false } }),
    });

    if (slug === 'psychic-bio') {
      setPageUrlCookie(url);
    }

    if (user?.isLeadCustomer) {
      const number = user?.customerPhoneNumber === '9999999999' ? '' : user?.customerPhoneNumber;
      setMultipleCookies({
        [ASK_ACCOUNT_INFO]: {
          ...accountInfoObject,
          extId: psychic.extId,
          custId: user?.custId,
          chat: false,
          dob: user?.userDateOfBirth,
          ncflowtype: 2,
          email: user?.userEmail,
          phoneNumber: number,
        },
      });
      router.push(`${NC_FUNNEL_SIGN_UP_URL}?extid=${psychic.extId}`);

      return;
    }

    onClick?.(e);
  };
  const closeModal = () => {
    setOpenedState(false);
  };

  const closeErrorModal = () => {
    setIsOopenErrorModal(false);
  };

  const ButtonComponent = isAuthenticated ? DataButton : DataLink;

  return (
    <>
      <ButtonComponent
        {...rest}
        link={link}
        href={url}
        className={cn(
          styles.button,
          styles[`shape${capitalizeFirstLetter(shape)}`],
          styles[`button${buttonName}`],
          className,
        )}
        gaData={{
          [GAReplacementValue.PSYCHIC_NAME]: psychicName,
          [GAReplacementValue.EXT_ID]: extId.toString(),
        }}
        onClick={localOnClick}
      >
        <ButtonIcon
          isSpriteImage={buttonName !== 'InQueue'}
          image={link.image}
          className={styles.buttonAsset}
          buttonName={buttonName}
        />
        <span className={styles.buttonTitle}>
          {link.title}
        </span>
      </ButtonComponent>
      <TalkModal
        psychicFrame={psychicFrame}
        psychic={psychic}
        isOpened={isOpened}
        closeModal={closeModal}
        block={psychicFrame?.talkButtonModal as Block}
        setOpenedState={setOpenedState}
        setPrimaryNumber={setPrimaryNumber}
        primaryNumber={primaryNumber}
      />
      <ErrorModal
        isOpened={isOopenErrorModal}
        closeModal={closeErrorModal}
      />
    </>
  );
};

export default TalkButton;
