import {
  FC,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Block } from 'src/__generated__/graphqlTypes';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import type { Store } from 'app-redux/types/storeTypes';
import { DataButton } from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';
import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';
import {
  ASK_ACCOUNT_INFO,
  CTA_TYPE,
  NC_FUNNEL_SIGN_UP_URL,
  PSYCHIC_TALK_COOKIE_BIO,
} from 'constants/constants';
import { accountInfoObject, getAccountPackages } from 'src/shared/lib/cookie/accountInfo';

import ECModal from './MessageButton/ECModal';
import { ButtonIcon } from './Icon';
import Modal from './Modal';
import styles from './styles.module.scss';

import { MessageButtonInterface } from '../config/declarations';
import { getUrl } from '../lib';
import { setMultipleCookies } from '../lib/ctaCookie';

const MessageButton: FC<MessageButtonInterface> = ({
  button,
  psychic,
  className,
  buttonName,
  shape = 'Rectangle',
  href = '',
  psychicFrame,
  onClick,
  ...rest
}) => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);
  const [isOpened, setOpenedState] = useState<boolean>(false);

  useEffect(() => {
    setOpenedState(false);
  }, [isAuthenticated]);

  const trackExponea = useExponeaProductSelectedTracking(psychic);
  const router = useCustomRouter();

  if (!button?.link) {
    return null;
  }

  const { extId, psychicName } = psychic;
  const { link } = button;
  const nameForUrl = (psychicName || '').toLowerCase().replace(/\s/g, '');
  const replacementValues = { extId: `${extId}`, psychicName: nameForUrl };
  const url = getUrl('', replaceContentfulVariables(href || link?.src!, replacementValues));
  const sharedProps = {
    link,
    className: cn(
      styles.button,
      styles[`shape${capitalizeFirstLetter(shape)}`],
      styles[`button${buttonName}`],
      className,
    ),
    gaData: {
      [GAReplacementValue.PSYCHIC_NAME]: psychicName,
      [GAReplacementValue.EXT_ID]: extId.toString(),
    },
    ...rest,
  };
  const children = (
    <>
      <ButtonIcon
        isSpriteImage
        image={link.image}
        className={styles.buttonAsset}
        buttonName={buttonName}
      />
      <span className={styles.buttonTitle}>
        {link.title}
      </span>
    </>
  );
  const onClickLocal = (e: MouseEvent<HTMLButtonElement>) => {
    setOpenedState((prev) => !prev);
    trackExponea(buttonName);
    onClick?.(e);
    setMultipleCookies(
      {
        [CTA_TYPE]: buttonName.toLowerCase(),
        [PSYCHIC_TALK_COOKIE_BIO]: { ExtId: extId, Name: psychicName },
      },
    );
  };
  const closeModal = () => setOpenedState(false);

  /**
   * Here is used {@link DataButton} instead of {@link DataLink} because
   * for some reason {@link trackExponea} doesn't send events on {@link DataLink} click
   * on Safari mobile. It might be connected with the way how button was implemented but
   * even with the same implementation with different buttons it didn't work
    */

  if (user?.isLeadCustomer) {
    return (
      <DataButton
        {...sharedProps}
        onClick={async () => {
          await trackExponea(buttonName);
          setMultipleCookies({
            ...(user && {
              [ASK_ACCOUNT_INFO]: {
                ...accountInfoObject,
                extId: psychic.extId,
                custId: user.custId,
                chat: false,
                dob: user.userDateOfBirth,
                package: getAccountPackages('20')[1],
                ncflowtype: 2,
                email: user.userEmail,
              },
            }),
          });
          router.push(`${NC_FUNNEL_SIGN_UP_URL}?nc=1`);
        }}
      >
        {children}
      </DataButton>
    );
  }

  if (isAuthenticated && !button.contentTypesCollection?.items?.length) {
    return (
      <DataButton
        {...sharedProps}
        onClick={async () => {
          await trackExponea(buttonName);
          router.push(url);
        }}
      >
        {children}
      </DataButton>
    );
  }

  if (isAuthenticated) {
    return (
      <>
        <ECModal
          isOpened={isOpened}
          psychicFrame={psychicFrame}
          psychic={psychic}
          block={button}
          messageIcon={link.image}
          closeModal={closeModal}
        />
        <DataButton
          {...sharedProps}
          onClick={onClickLocal}
        >
          {children}
        </DataButton>
      </>
    );
  }

  return (
    <>
      <Modal
        isOpened={isOpened}
        buttonTitle={button.title}
        content={(button as Block).content}
        closeModal={closeModal}
      />
      <DataButton
        {...sharedProps}
        onClick={onClickLocal}
      >
        {children}
      </DataButton>
    </>
  );
};

export default MessageButton;
